//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
import AuthForm from "@/components/Auth/Form";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      message: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      message = "已达到匿名用户的请求限制"
    }) {
      this.message = message;
    }
    /* async submit({ close }) {
       
    } */
  },

  computed: {},
  components: {
    AuthForm,
    Modal,
    Wrapper
  }
};